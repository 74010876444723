import { Base, createApp, importWhenVisible } from '@studiometa/js-toolkit';
import { Modal, Accordion, AnchorScrollTo } from '@studiometa/ui';
import Select from './atoms/Select.js';
import Figure from './atoms/Figure.js';
import FormReset from './atoms/FormReset.js';
import OpenSearchPanel from './atoms/OpenSearchPanel.js';
import OpenFiltersPanel from './atoms/OpenFiltersPanel.js';
import Slider from './molecules/Slider.js';
import HotFiltersController from './molecules/HotFiltersController.js';
import JQueryZoom from './molecules/JQueryZoom.js';
import SuggestionsFrame from './organisms/SuggestionsFrame.js';

/**
 * App class.
 * @extends {Base<AppProps>}
 */
class App extends Base {
  /**
   * Config.
   */
  static config = {
    name: 'App',
    components: {
      'a[href^="#"]': AnchorScrollTo,
      Figure,
      FiltersPanel: Modal,
      SearchPanel: Modal,
      Accordion,
      Select,
      FormReset,
      OpenSearchPanel,
      OpenFiltersPanel,
      Slider,
      HotFiltersController,
      JQueryZoom,
      SuggestionsFrame,
      Map: (app) => importWhenVisible(() => import('./organisms/Map/index.js'), 'Map', app),
    },
  };

  /**
   * On Select open
   * @param {boolean} status
   * @param {number} index
   */
  onSelectOpen(status, index) {
    this.$children.Select.forEach((select, i) => {
      if (i !== index) {
        select.close();
      }
    });
  }

  /**
   * on OpenSearchPanel Click
   */
  onOpenSearchPanelClick() {
    this.$children?.SearchPanel?.[0]?.open();
  }

  /**
   * on OpenFiltersPanel Click
   */
  onOpenFiltersPanelClick() {
    this.$children?.FiltersPanel?.[0]?.open();
  }
}

export default createApp(App);
