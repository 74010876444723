import { Base } from '@studiometa/js-toolkit';
import $ from 'jquery';
import initZoom from '../../v4/vendors/zoom.js';

// @see http://www.jacklmoore.com/zoom/ for doc.
initZoom($);

/**
 * JQueryZoom class
 */
export default class JQueryZoom extends Base {
  static config = {
    name: 'JQueryZoom',
    options: {
      url: String,
    },
  };

  /**
   * Mounted
   */
  mounted() {
    $(this.$el).zoom({
      url: this.$options.url,
      magnify: 0.6,
    });
  }
}
