import { Frame } from '@studiometa/ui';
import { isDirectChild } from '@studiometa/js-toolkit';
import SuggestionsInput from '../atoms/SuggestionsInput.js';

/**
 * SuggestionsFrame class.
 */
export default class SuggestionsFrame extends Frame {
  /**
   * Config.
   */
  static config = {
    ...Frame.config,
    name: 'SuggestionsFrame',
    components: {
      ...Frame.config.components,
      SuggestionsInput,
    },
    log: true,
  };

  /**
   * On SuggestionsInput update.
   * @param {string} value
   * @param {number} index
   */
  onSuggestionsInputUpdate(value, index) {
    const input = this.$children.SuggestionsInput[index];

    // Prevent propagation of nested frames
    if (!isDirectChild(this, 'Frame', 'SuggestionsInput', input)) {
      return;
    }

    this.$log('onSuggestionsInputUpdate', index, value);
    const url = new URL(input.$options.endpoint);
    url.protocol = window.location.protocol;
    const formData = new FormData();
    formData.append(input.$el.name, value);
    url.search = new URLSearchParams(formData).toString();
    this.goTo(url.toString());
  }
}
