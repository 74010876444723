import {
  Slider as SliderCore,
  SliderBtn,
  SliderDrag,
  SliderItem,
  SliderProgress,
  SliderDots,
} from '@studiometa/ui';
import SliderChild from './SliderChild.js';
import withWindowWidthObserver from '../decorators/withWindowWidthObserver.js';

/**
 * Slider
 */
export default class Slider extends withWindowWidthObserver(SliderCore) {
  static config = {
    ...SliderCore.config,
    name: 'Slider',
    components: {
      SliderDrag,
      SliderDots,
      SliderBtn,
      SliderItem,
      SliderProgress,
      Slider,
      SliderChild,
    },
  };
}
