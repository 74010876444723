import { Base } from '@studiometa/js-toolkit';
import { debounce } from '@studiometa/js-toolkit/utils';

/**
 * SuggestionsInput class.
 */
export default class SuggestionsInput extends Base {
  /**
   * Config.
   */
  static config = {
    name: 'SuggestionsInput',
    emits: ['update'],
    options: {
      endpoint: {
        type: String,
        default: window.location.toString(),
      },
    },
  };

  update = debounce(() => {
    this.$emit('update', this.$el.value);
  }, 300);

  /**
   * Keyed
   * @param {object} props
   * @param {boolean} props.isUp
   */
  keyed({ isUp }) {
    if (!isUp || document.activeElement !== this.$el) {
      return;
    }
    this.update();
  }
}
