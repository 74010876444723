import { Base } from '@studiometa/js-toolkit';
import { focusTrap } from '@studiometa/js-toolkit/utils';

const { trap, untrap, saveActiveElement } = focusTrap();

/**
 * Select
 */
export default class Select extends Base {
  static config = {
    name: 'Select',
    emits: ['change', 'open', 'close'],
    refs: [
      'input',
      'placeholder',
      'toggle[]',
      'open[]',
      'close[]',
      'reset[]',
      'options[]',
      'container',
      'dropdown',
      'dropdownOptions[]',
    ],
    options: {
      isOpen: Boolean,
      hasAutofocus: Boolean,
      mode: {
        type: String,
        default: 'display_value',
      },
    },
  };

  placeholderTextContent = '';

  closestForm = null;

  bindedReset = null;

  firstCharacters = [];

  /**
   * is Multiple ?
   * @returns {boolean}
   */
  get isMultiple() {
    return this.$refs.input.hasAttribute('multiple');
  }

  /**
   * Options
   * @returns {Array}
   */
  get options() {
    return (this.$refs.options ?? []).map((option, index) => ({
      index,
      element: option,
      dropdownElement: this.$refs.dropdownOptions[index],
      isSelected: option.selected,
      label: option.textContent,
      value: option.value,
    }));
  }

  /**
   * Open
   */
  open() {
    if (this.$options.isOpen) {
      return;
    }
    this.$refs.dropdown.setAttribute('aria-hidden', 'true');
    this.$refs.input.setAttribute('disabled', 'true');
    this.options.forEach(({ dropdownElement }) => dropdownElement.removeAttribute('disabled'));
    if (this.$options.hasAutofocus) {
      saveActiveElement();
      this.options[0].dropdownElement.focus();
    }
    this.$options.isOpen = true;
    [...this.$refs.open, ...this.$refs.close, ...this.$refs.toggle].forEach((element) => {
      element.setAttribute('aria-expanded', 'true');
    });
    this.$emit('open', true);
  }

  /**
   * Close
   */
  close() {
    if (!this.$options.isOpen) {
      return;
    }
    this.$refs.dropdown.setAttribute('aria-hidden', 'false');
    this.$refs.input.removeAttribute('disabled');
    this.options.forEach(({ dropdownElement }) => dropdownElement.setAttribute('disabled', 'true'));
    this.$options.isOpen = false;
    [...this.$refs.open, ...this.$refs.close, ...this.$refs.toggle].forEach((element) => {
      element.setAttribute('aria-expanded', 'false');
    });
    untrap();
    this.$emit('close', false);
  }

  /**
   * Close the modal on `ESC` and trap the tabulation.
   * @param {object}  props
   * @param {Event}   props.event
   * @param {boolean} props.isUp
   * @param {boolean} props.isDown
   * @param {boolean} props.ESC
   */
  keyed({ event, isUp, isDown, ESC }) {
    if (!this.$options.isOpen) {
      return;
    }

    // Scroll to the first item which starts by the `event.key` pressed.
    const valueIndex = this.firstCharacters.indexOf(event.key);
    if (valueIndex >= 0) {
      this.scrollToIndex(valueIndex);
    }

    if (isDown) {
      trap(this.$refs.container ?? this.$refs.dropdown, event);
    }

    if (ESC && isUp) {
      this.close();
    }
  }

  /**
   * On open click
   */
  onOpenClick() {
    this.open();
  }

  /**
   * On close click
   */
  onCloseClick() {
    this.close();
  }

  /**
   * On toogle click
   */
  onToggleClick() {
    if (this.$options.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Close on click-outside.
   * @param {Event} event
   */
  onDocumentClick(event) {
    if (!(this.$refs.container ?? this.$refs.dropdown).contains(event.target)) {
      this.close();
    }
  }

  /**
   * Changed
   * @param {boolean} noEvent
   */
  changed(noEvent) {
    const selectedOptions = this.options.filter(({ isSelected }) => isSelected);

    if (selectedOptions.length > 0) {
      if (this.$options.mode === 'append_count') {
        this.$refs.placeholder.textContent =
          this.isMultiple && selectedOptions.length > 1
            ? `${this.placeholderTextContent} (${selectedOptions.length})`
            : `${this.placeholderTextContent} (1)`;
      } else {
        this.$refs.placeholder.textContent =
          this.isMultiple && selectedOptions.length > 1
            ? `${selectedOptions[0].label} (+ ${selectedOptions.length - 1})`
            : selectedOptions[0].label;
      }
    } else {
      this.$refs.placeholder.textContent = this.placeholderTextContent;
    }

    const selectedOptionsValues = selectedOptions.map(({ value }) => value);

    if (noEvent) {
      return;
    }

    this.$emit(
      'change',
      this.isMultiple ? selectedOptionsValues : selectedOptionsValues[0] ?? null,
    );
  }

  /**
   * On input change
   */
  onInputChange() {
    this.options.forEach((option) => {
      option.dropdownElement.checked = option.isSelected;
    });
    this.changed();
  }

  /**
   * On dropdown option change
   * @param {Event} event
   * @param {number} index
   */
  onDropdownOptionsChange(event, index) {
    this.options[index].element.selected = event.target.checked;
    this.changed();
  }

  /**
   * Reset
   */
  reset() {
    this.options.forEach((option) => {
      option.dropdownElement.checked = false;
      option.element.selected = false;
    });
    this.changed();
  }

  /**
   * On reset click
   */
  onResetClick() {
    this.reset();
  }

  /**
   * Scroll to an element in the dropdown.
   * @param {number} index
   */
  scrollToIndex(index) {
    const element = this.$refs.dropdown.querySelector(`:nth-child(${index + 1})`);
    this.$refs.dropdown.scrollTop = element.offsetTop;
  }

  /**
   * Mounted
   */
  mounted() {
    if (
      !['input', 'options[]', 'dropdown', 'dropdownOptions[]'].every((name) =>
        name.endsWith('[]') ? !!this.$refs[name.slice(0, -2)]?.[0] : !!this.$refs[name],
      ) ||
      this.$refs.options.length !== this.$refs.dropdownOptions.length
    ) {
      this.$destroy();
      // @TODO: warn
      return;
    }

    this.closestForm = this.$el.closest('form');
    if (this.closestForm) {
      this.bindedReset = this.reset.bind(this);
      this.closestForm.addEventListener('reset', this.bindedReset);
    }

    if (this.$refs.placeholder) {
      this.placeholderTextContent = this.$refs.placeholder.textContent;
    }
    [...this.$refs.open, ...this.$refs.close, ...this.$refs.toggle].forEach((element) => {
      element.setAttribute('aria-controls', `${this.$id}-dropdown`);
      element.setAttribute('aria-haspopup', 'true');
      element.setAttribute('aria-expanded', 'false');
    });
    this.$refs.dropdown.setAttribute('id', `${this.$id}-dropdown`);
    this.changed();

    this.firstCharacters = [...this.$el.querySelectorAll('label')].map((el) =>
      // eslint-disable-next-line unicorn/prefer-dom-node-text-content
      el.innerText.charAt(0).toLowerCase(),
    );
  }

  /**
   * Destroyed
   */
  destroyed() {
    if (this.closestForm) {
      this.closestForm.removeEventListener('reset', this.bindedReset);
    }
  }
}
