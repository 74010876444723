/* eslint-disable require-jsdoc */
// @ts-nocheck
import {
  Slider as SliderCore,
  SliderDrag as SliderChildDrag,
  SliderItem as SliderChildItem,
  SliderBtn as SliderChildBtn,
} from '@studiometa/ui';

/**
 * SliderChild
 * @description This is a Copy/Paste of the default Slider Component, only to rename SliderItem with SliderChildItem
 * Since putting a Slider Component inside another Slider is not working properly
 * SliderItem for parent an child component are resolved on the parent and calculation is messed up
 */
export default class SliderChild extends SliderCore {
  /**
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'SliderChild',
    components: {
      SliderChildDrag,
      SliderChildItem,
      SliderChildBtn,
    },
  };

  get indexMax() {
    return this.$children.SliderChildItem.length - 1;
  }

  get currentSliderItem() {
    return this.$children.SliderChildItem[this.currentIndex];
  }

  getStates() {
    const { wrapper } = this.$refs;
    const originRect = wrapper.getBoundingClientRect();

    this.origins = {
      left: originRect.left,
      center: originRect.x + originRect.width / 2,
      right: originRect.x + originRect.width,
    };

    const states = this.$children.SliderChildItem.map((item) => ({
      x: {
        left: (item.rect.x - this.origins.left) * -1,
        center: (item.rect.x + item.rect.width / 2 - this.origins.center) * -1,
        right: (item.rect.x + item.rect.width - this.origins.right) * -1,
      },
    }));

    if (this.$options.contain) {
      const { mode } = this.$options;
      // Find state where last child has passed the wrapper bound completely
      if (mode === 'left') {
        const lastChild = this.$children.SliderChildItem.at(-1);

        const maxState = states.find((state) => {
          const lastChildPosition =
            lastChild.rect.x - this.origins.left + lastChild.rect.width + state.x.left;
          const diffWithWrapperBound = originRect.width - lastChildPosition;
          if (diffWithWrapperBound > 0) {
            state.x.left = Math.min(state.x.left + diffWithWrapperBound, 0);
            return true;
          }

          return false;
        });

        if (maxState) {
          return states.map((state) => {
            state.x.left = Math.max(state.x.left, maxState.x.left);
            return state;
          });
        }
      }

      if (mode === 'right') {
        const maxStateIndex = states.findIndex((state) => state.x.right <= 0);
        const maxState = maxStateIndex < 0 ? states.at(-1) : states[maxStateIndex - 1];

        return states.map((state) => {
          state.x.right = maxStateIndex < 0 ? maxState.x.right : Math.min(state.x.right, 0);
          return state;
        });
      }

      if (mode === 'center') {
        console.warn(
          `[${this.$id}]`,
          'The `center` mode is not yet compatible with the `contain` mode.',
        );
      }
    }

    return states;
  }

  getVisibleItems(target) {
    return this.$children.SliderChildItem.filter(
      (item) => item.isVisible || item.willBeVisible(target),
    );
  }

  getInvisibleItems(target) {
    return this.$children.SliderChildItem.filter(
      (item) => !item.isVisible && !item.willBeVisible(target),
    );
  }

  /**
   * Listen to the Draggable `start` event.
   */
  onSliderChildDragStart() {
    this.onSliderDragStart();
  }

  /**
   * Listen to the Draggable `drag` event.
   * @param {object} props
   */
  onSliderChildDragDrag(props) {
    this.onSliderDragDrag(props);
  }

  /**
   * Listen to the Draggable `drop` event and find the new active slide.
   * @param {object} props
   */
  onSliderChildDragDrop(props) {
    this.onSliderDragDrop(props);
  }
}
