import { useResize } from '@studiometa/js-toolkit';

/**
 * Test the window width of the given Base instance and return the hook to call.
 * @param   {Base<withWindowWidthObserverProps>} instance The component's instance.
 * @param   {number} windowWidth The window width to test.
 * @returns {string} The action to trigger.
 */
function testWindowWidth(instance, windowWidth = useResize().props().width) {
  const { minWindowWidth, maxWindowWidth } = instance.$options;
  const isBiggerThanMinWidth = minWindowWidth && minWindowWidth <= windowWidth;
  const isSmallerThanMaxWidth = maxWindowWidth > 0 && maxWindowWidth > windowWidth;

  if (isBiggerThanMinWidth || isSmallerThanMaxWidth) {
    return '$mount';
  }

  return '$destroy';
}

/**
 * Test if the given instance is configured for breakpoints.
 * @param  {Base<withWindowWidthObserverProps>} instance A Base class instance.
 * @returns {boolean} True if configured correctly, false otherwise.
 */
function hasWindowWidthConfiguration(instance) {
  const { minWindowWidth, maxWindowWidth } = instance.$options;
  return Boolean(minWindowWidth || (maxWindowWidth && maxWindowWidth > 0));
}

/**
 * Add the current instance to the resize service.
 * @param {string} key      The key for the resize service callback.
 * @param {Base}   instance The instance to observe.
 */
function addToResize(key, instance) {
  const { add, has } = useResize();

  if (!has(key)) {
    add(key, ({ width }) => {
      const action = testWindowWidth(instance, width);

      // Always destroy before mounting
      if (action === '$destroy' && instance.$isMounted) {
        instance[action]();
      } else if (action === '$mount' && !instance.$isMounted) {
        setTimeout(() => instance[action](), 0);
      }
    });
  }
}

/**
 * Window Width Observer class.
 * @param {Class} BaseClass
 * @returns {Class}
 */
export default function withWindowWidthObserver(BaseClass) {
  /**
   * Class.
   */
  class WithWindowWidthObserver extends BaseClass {
    /**
     * Config.
     */
    static config = {
      ...BaseClass.config,
      name: `${BaseClass.config.name}WithWindowWidthObserver`,
      options: {
        ...(BaseClass.config?.options || {}),
        maxWindowWidth: Number,
        minWindowWidth: Number,
      },
    };

    /**
     * Watch for the document resize to test the width.
     * @param {HTMLElement} element
     */
    constructor(element) {
      super(element);

      const { remove } = useResize();

      const key = `WindowWidthObserver-${this.$id}`;

      // Watch change on the `data-options` attribute to emit the `set:options` event.
      const mutationObserver = new MutationObserver(([mutation]) => {
        if (
          mutation.type === 'attributes' &&
          (mutation.attributeName === 'data-options' ||
            mutation.attributeName.startsWith('data-option-'))
        ) {
          // Stop here silently when no width configuration given.
          if (!hasWindowWidthConfiguration(this)) {
            this.$mount();
            remove(key);
            return;
          }

          addToResize(key, this);
        }
      });
      mutationObserver.observe(this.$el, { attributes: true });

      // Stop here silently when no width configuration given.
      if (!hasWindowWidthConfiguration(this)) {
        return;
      }

      addToResize(key, this);
    }

    /**
     * Override the default $mount method to prevent component's from being mounted when they should not.
     * @returns {Base}
     */
    $mount() {
      // Execute normal behavior when no width configuration given.
      if (!hasWindowWidthConfiguration(this)) {
        return super.$mount();
      }

      const action = testWindowWidth(this);
      if (action === '$mount') {
        return super.$mount();
      }

      return this;
    }
  }

  // @ts-ignore
  return WithWindowWidthObserver;
}
