// Ugly import @see http://www.jacklmoore.com/zoom/ for doc.
export default function (o) {
  const t = {
    url: !1,
    callback: !1,
    target: !1,
    duration: 120,
    on: 'mouseover',
    touch: !0,
    onZoomIn: !1,
    onZoomOut: !1,
    magnify: 1,
  };
  (o.zoom = function (t, n, e, i) {
    let u;
    let c;
    let a;
    let r;
    let m;
    let l;
    let s;
    const f = o(t);
    const h = f.css('position');
    const d = o(n);
    return (
      (t.style.position = /(absolute|fixed)/.test(h) ? h : 'relative'),
      (t.style.overflow = 'hidden'),
      (e.style.width = e.style.height = ''),
      o(e)
        .addClass('zoomImg')
        .css({
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
          width: e.width * i,
          height: e.height * i,
          border: 'none',
          maxWidth: 'none',
          maxHeight: 'none',
        })
        .appendTo(t),
      {
        init() {
          (c = f.outerWidth()),
            (u = f.outerHeight()),
            n === t ? ((r = c), (a = u)) : ((r = d.outerWidth()), (a = d.outerHeight())),
            (m = (e.width - c) / r),
            (l = (e.height - u) / a),
            (s = d.offset());
        },
        move(o) {
          let t = o.pageX - s.left;
          let n = o.pageY - s.top;
          (n = Math.max(Math.min(n, a), 0)),
            (t = Math.max(Math.min(t, r), 0)),
            (e.style.left = `${t * -m}px`),
            (e.style.top = `${n * -l}px`);
        },
      }
    );
  }),
    (o.fn.zoom = function (n) {
      return this.each(function () {
        const e = o.extend({}, t, n || {});
        const i = (e.target && o(e.target)[0]) || this;
        const u = this;
        const c = o(u);
        const a = document.createElement('img');
        const r = o(a);
        const m = 'mousemove.zoom';
        let l = !1;
        let s = !1;
        if (!e.url) {
          const f = u.querySelector('img');
          if ((f && (e.url = f.dataset.src || f.currentSrc || f.src), !e.url)) return;
        }
        c.one(
          'zoom.destroy',
          function (o, t) {
            c.off('.zoom'),
              (i.style.position = o),
              (i.style.overflow = t),
              (a.onload = null),
              r.remove();
          }.bind(this, i.style.position, i.style.overflow),
        ),
          (a.onload = function () {
            function t(t) {
              f.init(),
                f.move(t),
                r
                  .stop()
                  .fadeTo(
                    o.support.opacity ? e.duration : 0,
                    1,
                    o.isFunction(e.onZoomIn) ? e.onZoomIn.call(a) : !1,
                  );
            }
            function n() {
              r.stop().fadeTo(e.duration, 0, o.isFunction(e.onZoomOut) ? e.onZoomOut.call(a) : !1);
            }
            var f = o.zoom(i, u, a, e.magnify);
            e.on === 'grab'
              ? c.on('mousedown.zoom', function (e) {
                  e.which === 1 &&
                    (o(document).one('mouseup.zoom', function () {
                      n(), o(document).off(m, f.move);
                    }),
                    t(e),
                    o(document).on(m, f.move),
                    e.preventDefault());
                })
              : e.on === 'click'
              ? c.on('click.zoom', function (e) {
                  return l
                    ? void 0
                    : ((l = !0),
                      t(e),
                      o(document).on(m, f.move),
                      o(document).one('click.zoom', function () {
                        n(), (l = !1), o(document).off(m, f.move);
                      }),
                      !1);
                })
              : e.on === 'toggle'
              ? c.on('click.zoom', function (o) {
                  l ? n() : t(o), (l = !l);
                })
              : e.on === 'mouseover' &&
                (f.init(), c.on('mouseenter.zoom', t).on('mouseleave.zoom', n).on(m, f.move)),
              e.touch &&
                c
                  .on('touchstart.zoom', function (o) {
                    o.preventDefault(),
                      s
                        ? ((s = !1), n())
                        : ((s = !0),
                          t(o.originalEvent.touches[0] || o.originalEvent.changedTouches[0]));
                  })
                  .on('touchmove.zoom', function (o) {
                    o.preventDefault(),
                      f.move(o.originalEvent.touches[0] || o.originalEvent.changedTouches[0]);
                  })
                  .on('touchend.zoom', function (o) {
                    o.preventDefault(), s && ((s = !1), n());
                  }),
              o.isFunction(e.callback) && e.callback.call(a);
          }),
          a.setAttribute('role', 'presentation'),
          (a.alt = ''),
          (a.src = e.url);
      });
    }),
    (o.fn.zoom.defaults = t);
}
