import { Base } from '@studiometa/js-toolkit';
/**
 * OpenFiltersPanel class.
 */
export default class OpenFiltersPanel extends Base {
  /**
   * Config.
   */
  static config = {
    name: 'OpenFiltersPanel',
  };
}
