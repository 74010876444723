import { FigureTwicpics } from '@studiometa/ui';
import { withLeadingSlash } from '@studiometa/js-toolkit/utils';
import { isProd, isBot } from '../utils/index.js';

/**
 * Normalize the given size to the step option.
 * @param {FigureTwicpics} that
 * @param {string} prop
 * @returns {number}
 */
function normalizeSize(that, prop) {
  const { step } = that.$options;
  return Math.ceil(that.$refs.img[prop] / step) * step;
}

/**
 * Figure
 */
export default class Figure extends FigureTwicpics {
  static config = {
    ...FigureTwicpics.config,
    name: 'Figure',
  };

  /**
   * get domain
   * @returns {string}
   */
  get domain() {
    return isProd() ? 'cdn.studiometa.fr' : 'studiometa.twic.pics';
  }

  /**
   * path
   * @returns {string}
   */
  get path() {
    return isProd() ? 'nobilis.fr' : 'nobilis.studiometa.dev';
  }

  /**
   * Format the source for Twicpics.
   * @param {string} src
   * @returns {string}
   */
  formatSrc(src) {
    const url = new URL(src, 'https://localhost');
    url.host = this.domain;
    url.port = '';

    if (this.path && !url.pathname.startsWith(withLeadingSlash(this.path))) {
      url.pathname = `/${this.path}${url.pathname}`;
    }

    let width = normalizeSize(this, 'offsetWidth');
    let height = normalizeSize(this, 'offsetHeight');

    if (!isBot) {
      width *= window.devicePixelRatio;
      height *= window.devicePixelRatio;
    }

    url.searchParams.set(
      'twic',
      ['v1', this.$options.transform, `${this.$options.mode}=${width}x${height}`]
        .filter(Boolean)
        .join('/'),
    );

    url.search = decodeURIComponent(url.search);

    return url.toString();
  }
}
