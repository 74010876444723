import { Base } from '@studiometa/js-toolkit';
/**
 * OpenSearchPanel class.
 */
export default class OpenSearchPanel extends Base {
  /**
   * Config.
   */
  static config = {
    name: 'OpenSearchPanel',
  };
}
