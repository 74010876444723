import { Base } from '@studiometa/js-toolkit';
import Select from '../atoms/Select.js';

/**
 * Hot Filters Controller
 */
export default class HotFiltersController extends Base {
  static config = {
    name: 'HotFiltersController',
    refs: ['checkboxes[]'],
    options: {
      target: String,
      autoSubmit: Boolean,
    },
    components: {
      Select,
    },
    log: true,
  };

  targetForm = null;

  /**
   * On Select change
   * @param {Array} values
   * @param {number} index
   */
  onSelectChange(values, index) {
    const name = this.$children.Select[index]?.$refs?.input?.name ?? null;
    if (!name) {
      return;
    }

    const inputs = this.targetForm.querySelectorAll(`input[name="${name}"]`);
    inputs.forEach((input) => {
      input.checked = values.includes(input.value);
    });

    if (!this.$options.autoSubmit) {
      return;
    }

    this.targetForm.submit();
  }

  /**
   * On checkboxes change
   * @param {Event} event
   * @param {number} index
   */
  onCheckboxesChange(event, index) {
    const ref = this.$refs.checkboxes[index];
    if (!ref) {
      return;
    }

    const name = ref?.name ?? null;
    const value = ref?.value ?? null;
    const checked = ref?.checked ?? false;
    if (!name || !value) {
      return;
    }

    const inputs = this.targetForm.querySelectorAll(`input[name="${name}"][value="${value}"]`);
    inputs.forEach((input) => {
      input.checked = checked;
    });

    if (!this.$options.autoSubmit) {
      return;
    }

    this.targetForm.submit();
  }

  /**
   * Mounted
   */
  mounted() {
    this.targetForm = document.querySelector(this.$options.target);
  }
}
